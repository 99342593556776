function toggleMobileMenu() {
    var siteCover = document.querySelector('.site-cover');
    var body = document.querySelector('body');
    var menu = document.querySelector('.mobile-menus');
    var trigger = document.querySelector('.js-mobile-menu');
    var header = document.querySelector('.main-header');

    menu.classList.toggle('is-open');
    siteCover.classList.toggle('is-active');
    body.classList.toggle('noscroll');
    trigger.classList.toggle('is-active');
    header.classList.toggle('is-active');

    slideToggle(menu);
}

var hamburger = document.querySelectorAll('.js-mobile-menu');

for (var i = 0, len = hamburger.length; i < len; i++) {
    hamburger[i].addEventListener('click', function(e) {
        e.preventDefault();
        toggleMobileMenu();
    });
}

var siteCover = document.querySelector('.site-cover');
var menu = document.querySelector('.mobile-menu');

siteCover.addEventListener('click', function(e) {
    if (menu.classList.contains('is-open')) {
        toggleMobileMenu();
    }
});

document.addEventListener('keyup', function(e) {
    if (e.key == 'Escape' && menu.classList.contains('is-open')) {
        toggleMobileMenu();
    }
});

// var mobileSubMenuTriggers = document.querySelectorAll(
//     '.mobile-menu .menu-item-has-children'
// );
//
// for (var i = 0, len = mobileSubMenuTriggers.length; i < len; i++) {
//     var trigger = mobileSubMenuTriggers[i];
//
//     trigger.insertAdjacentHTML(
//         'afterbegin',
//         '<div class="sub-menu-toggle"><svg class="icon black"> <use xlink:href="#icon-title"></use> </svg></div>'
//     );
//     trigger.addEventListener('click', function(e) {
//         if (e.target.getAttribute('href') == null) {
//             e.preventDefault();
//             var subMenu = this.querySelector('.sub-menu');
//             var menu = document.querySelector('.mobile-menu');
//
//             slideToggle(subMenu);
//             slideDown(menu);
//         }
//     });
// }
