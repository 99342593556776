var lazy = [];

function setLazy() {
    lazy = document.getElementsByClassName('lazy');
    // console.log('Found ' + lazy.length + ' lazy images');
}

export function lazyLoad() {
    for (var i = 0; i < lazy.length; i++) {
        var item = lazy[i];

        if (isInViewport(item)) {
            if (item.getAttribute('data-src') && !item.querySelector('img')) {
                var img = document.createElement('img');
                img.setAttribute('src', item.getAttribute('data-src'));
                img.setAttribute('srcset', item.getAttribute('data-srcset'));
                img.setAttribute('alt', item.getAttribute('data-alt'));
                img.setAttribute('sizes', item.getAttribute('data-sizes'));
                item.appendChild(img);

                img.addEventListener('load', function () {
                    this.classList.add('lazy-loaded');
                });
            }
            if (
                item.classList.contains('video') &&
                !item.classList.contains('loaded')
            ) {
                const video = item.querySelector('video');
                let attr = {
                    small: video.getAttribute('data-small-src'),
                    medium: video.getAttribute('data-medium-src'),
                    large: video.getAttribute('data-large-src'),
                };

                let src = '';

                if (window.innerWidth <= 600) {
                    if (attr.small != '') {
                        src = attr.small;
                    } else if (attr.medium != '') {
                        src = attr.medium;
                    } else {
                        src = attr.large;
                    }
                } else if (window.innerWidth > 600 && window.innerWidth < 800) {
                    if (attr.medium != '') {
                        src = attr.medium;
                    } else {
                        src = attr.large;
                    }
                } else {
                    if (attr.large != '') {
                        src = attr.large;
                    } else {
                        src = attr.medium;
                    }
                }

                if (src == null && attr.large == null) {
                    src = attr.medium;
                }

                video.setAttribute('src', src);

                setTimeout(function () {
                    video.play();
                }, 500);

                item.classList.add('loaded');
            }
        }
    }
}

function isInViewport(el) {
    var rect = el.getBoundingClientRect();

    return (
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <=
            (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <=
            (window.innerWidth + 100 || document.documentElement.clientWidth)
    );
}

export function registerListener(event, func) {
    if (window.addEventListener) {
        window.addEventListener(event, func);
    } else {
        window.attachEvent('on' + event, func);
    }
}

// If you want to debounce it for performance reasons then use this for the
// event listener. This waits until the scroll finishes though.
var debounceLazyLoadFn = _.throttle(function () {
    lazyLoad();
}, 300);

registerListener('load', setLazy);
registerListener('load', lazyLoad);
registerListener('scroll', debounceLazyLoadFn);
registerListener('resize', lazyLoad);
