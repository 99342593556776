import SmoothScroll from 'smooth-scroll';
import Masonry from 'masonry-layout';
// Generic scripts can go in here

var scroll = new SmoothScroll('a[data-scroll]');

// vanilla JS
// init with element
var grid = document.querySelector('.grid');

if (grid) {
    var msnry = new Masonry(grid, {
        itemSelector: '.grid-item',
        // columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer',
        percentPosition: true,
    });

    // Firefox was not getting triggered properly
    setTimeout(function () {
        msnry.layout();
    }, 300);
}
