import Flickity from 'flickity';
import { lazyLoad } from './_lazyload';

var slides = document.querySelector('.slides');
if (slides) {
    var lazyLoadSlideImages = _.throttle(function () {
        lazyLoad();
    }, 1000);

    // trigger redraw for transition
    slides.offsetHeight;
    // This assumes that the first images are in the slideshow
    // document.addEventListener('imageLoaded', function() {
    slides.classList.add('ready');
    // });

    // element argument can be a selector string
    //   for an individual element
    var Slides = new Flickity(slides, {
        // options
        prevNextButtons: false,
        setGallerySize: true,
        imagesLoaded: true,
        pageDots: false,
        cellAlign: 'left',
        autoPlay: 5000,
        pauseAutoPlayOnHover: true,
        wrapAround: true,
    });

    // We want to trigger any images in the slideshow to show when moving
    // through
    Slides.on('scroll', lazyLoadSlideImages);
}
