import Flickity from 'flickity';
import { lazyLoad } from './_lazyload';

var perPage = 2;

if (window.windowWidth < 960) {
    perPage = 1;
}

if (document.querySelector('.issue-slides')) {
    // var Issues = new Siema({
    //     selector : '.issue-slides',
    //     duration: 300,
    //     perPage: perPage,
    //     easing: 'cubic-bezier(.65,.05,.36,1)',
    //     loop: true
    // });
    //
    // var prev = document.querySelector('.prev-issue');
    // var next = document.querySelector('.next-issue');
    //
    // prev.addEventListener('click', function() {Issues.prev()});
    // next.addEventListener('click', function() {Issues.next()});
    //
    // var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //
}

var issues = document.querySelector('.issue-slides');
if (issues) {
    var lazyLoadSlideImages = _.throttle(function () {
        lazyLoad();
    }, 1000);

    // trigger redraw for transition
    issues.offsetHeight;
    // This assumes that the first images are in the slideshow
    // document.addEventListener('imageLoaded', function() {
    issues.classList.add('ready');
    // });

    // element argument can be a selector string
    //   for an individual element
    var Issues = new Flickity(issues, {
        // options
        prevNextButtons: false,
        setGallerySize: true,
        imagesLoaded: true,
        pageDots: false,
        // wrapAround: true,
        cellAlign: 'left',
        // contain: true
    });

    // We want to trigger any images in the slideshow to show when moving
    // through
    Issues.on('scroll', lazyLoadSlideImages);

    var prev = document.querySelector('.prev-issue');
    var next = document.querySelector('.next-issue');

    prev.addEventListener('click', function () {
        Issues.previous(true, false);
    });
    next.addEventListener('click', function () {
        Issues.next(true, false);
    });
}
