import { registerListener } from './_lazyload';

function animateIn() {
    var animations = document.querySelectorAll('.animate:not(.is-animated)');

    Array.prototype.forEach.call(animations, function (el, i) {
        var $item = el,
            delay = 0,
            top = $item.getBoundingClientRect().top,
            bottom =
                window.pageYOffset +
                (window.innerHeight || document.documentElement.clientHeight);

        if (top + 20 < bottom || $item.classList.contains('dontWait')) {
            if ($item.getAttribute('data-delay')) {
                delay = $item.getAttribute('data-delay');
            }

            setTimeout(function () {
                $item.classList.add('is-animated');
            }, delay);
        }
    });
}

registerListener('load', animateIn);
registerListener('scroll', animateIn);
