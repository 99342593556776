import styleSelect from 'styleselect';

var select = document.querySelectorAll('.select');

[].forEach.call(select, function (el) {
    styleSelect(el);

    var selectElement = el.nextElementSibling;
    var selectWidth = selectElement.offsetWidth;
    selectElement.style.minWidth = selectWidth + 'px';
});

var filterTrigger = document.querySelector('.js-filters-trigger');

if (filterTrigger) {
    filterTrigger.addEventListener('click', function () {
        var form = document.querySelector('.filter-form');

        slideToggle(form);
        form.classList.toggle('is-open');

        this.classList.toggle('is-active');
    });
}

// Get the filters form element
var filters = document.querySelector('.filter-form');

// If there is one on the page
if (filters) {
    // Listen for when the form is submitted
    filters.addEventListener('submit', function (e) {
        // do nothing by default
        e.preventDefault();

        // Check which term has been selected
        var issues = this.querySelector('#story-terms');
        var selected = issues.options[issues.selectedIndex];

        // If there is a selection
        if (selected) {
            // Get which taxonomy the term applies to
            var taxonomy = selected.getAttribute('data-taxonomy');
            // Save the term
            var value = selected.value;
            // Create a hidden element
            var input = document.createElement('input');
            input.type = 'hidden';
            // Add the taxonomy and value
            input.name = taxonomy;
            input.value = value;

            // Add this to the form
            this.appendChild(input);
        }

        // Submit the form
        this.submit();
    });
}
