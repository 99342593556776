function scrollTop(){
  
    var top = this.scrollY;
    scrolled =  document.querySelectorAll('.js--scrolled');
    if (top >= 1) {
      for(var i = 0; i < scrolled.length; i++) {
        scrolled[i].classList.add('scrolled');
      };
    } else {
      for(var i = 0; i < scrolled.length; i++) {
        scrolled[i].classList.remove('scrolled');
      };    
    }
  // console.log(top);

}

function registerListener(event, func) {
    if (window.addEventListener) {
        window.addEventListener(event, func)
    } else {
        window.attachEvent('on' + event, func)
    }
}

// If you want to debounce it for performance reasons then use this for the
// event listener. This waits until the scroll finishes though.
var debounceScrollTop = _.throttle(function(){
   scrollTop();
}, 300);


registerListener('scroll', debounceScrollTop);
