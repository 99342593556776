import baguetteBox from 'baguettebox.js';

baguetteBox.run('.gallery', {
    afterShow: () => {
        document.querySelector('html').style.overflow = 'hidden';
    },
    afterHide: () => {
        document.querySelector('html').style.overflow = '';
    },
});
