import Rellax from 'rellax';

var rellaxEl = document.querySelector('.rellax');
if (rellaxEl) {
    var rellax = new Rellax('.rellax', {
        speed: 2,
        center: true,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false,
    });
}
