import Flickity from 'flickity';

var sliderSlides = document.querySelector('.slider-slides');
var quoteSlideControls = document.querySelector('.slider-controls');

if (sliderSlides) {
    var lazyLoadSlideImages = _.throttle(function () {
        lazyLoad();
    }, 1000);

    // trigger redraw for transition
    sliderSlides.offsetHeight;
    // This assumes that the first images are in the slideshow
    // document.addEventListener('imageLoaded', function() {
    sliderSlides.classList.add('ready');
    // });
    var slideshow = new Flickity(sliderSlides, {
        // options
        prevNextButtons: false,
        setGallerySize: true,
        imagesLoaded: true,
        pageDots: true,
        cellAlign: 'left',
        autoPlay: 5000,
        pauseAutoPlayOnHover: true,
        wrapAround: true,
    });

    // previous
    var previousButton = document.querySelector(
        '.slider-controls .slider-control--previous'
    );
    previousButton.addEventListener('click', function () {
        slideshow.previous();
    });

    // next
    var nextButton = document.querySelector(
        '.slider-controls .slider-control--next'
    );
    nextButton.addEventListener('click', function () {
        slideshow.next();
    });
}
