/*
 * TODO :
 * 1. ~~Play video on mobile by default~~
 * 2. Start and stop video when dragging
 * 3. When clicking not active pane, activate it
 */

// Get the wrapper which holds the two panes
var paneWrapper = document.querySelector('.dual-pane');

if (paneWrapper) {
    // Get any panes inside this wrapper
    var panes = paneWrapper.querySelectorAll('.pane');
}
// If we find some panes
if (panes) {

    // Loop through each of them ...
    for (var i = 0, len = panes.length; i < len; i++) {
        var pane = panes[i];

        // .. and listen for a mouseenter event
        pane.addEventListener('mouseenter', function() {

            // Find the desired state ( direction ) for the panes which is
            // saved on the pane elements
            var parentDirection = this.getAttribute('data-direction');

            // NOTE: This direction is based on where the two panes meet. When the
            // meeting point is to the right of center the direction it 'right'.

            // Set the direction on the wrapper
            paneWrapper.setAttribute('data-direction', parentDirection);

            // Find the video element in the active pane
            var video = this.querySelector('.video');

            // Play that video
            video.play();
        });

        // Listen for when the mouse leaves a pane
        pane.addEventListener('mouseleave', function() {

            // Find the video in that pane
            var video = this.querySelector('.video');

            // Pause this video
            video.pause();

            // Clear the wrappers state which will default to center
            paneWrapper.setAttribute('data-direction', '');
        });

        // Listen out for clicks on the panes
        pane.addEventListener('click', function(e) {
            // If we are dragging
            if (inDrag) {
                // Then we dont want to get redirected with any links
                e.preventDefault();
            } else {
                if (this.getAttribute('data-direction') == paneWrapper.getAttribute('data-direction')) {
                    this.querySelector('video').play();
                }
            }
        });
    }
}

// Set some defaults :

// Where the drag starts from
var dragStartX;

//  The elements offset
var objInitLeft;

// What the transform is when the mouse move starts
var transformStart;

// Keep a track of the dragging state
var inDrag = false;

// The main element used for dragging
var dragTarget = document.querySelector('.dual-pane');


/**
 * To set the desired state on the containing element.
 * This handles how the panes are shown, either to the left of the right.
 *
 * @param {obj} The javascript object for the container that holds the state.
 * @param {string} The direction to set on the container.
 */
function setPaneState(container, direction) {
    container.setAttribute('data-direction', direction);
}

/**
 * In order to set the transform on the element we need to know which css
 * property it supports
 *
 * @return {string} The browsers transform property
 */
function toWebkitOrNot() {
    if (typeof dragTarget.style.transform === 'string') {
      return 'transform';
    }
    return 'WebkitTransform';
}

/**
 * Working out the containers transform property. This gets set to the global
 * variable for transformStart.
 *
 * TODO: clean this up, suitable for refactoring
 *
 * @return {null} Returns nothing, sets a global variable
 */
function getTransformPosition() {
    var transformProperty = toWebkitOrNot();
    var computedStyle = document.defaultView.getComputedStyle(dragTarget, null);
    var transformMatrix = computedStyle.getPropertyValue(transformProperty);
    var matrix = transformMatrix.replace(/[^0-9\-.,]/g, '').split(',');
    var x = matrix[12] || matrix[4];

    var elWidth = dragTarget.offsetWidth;

    var percent = (x / elWidth) * 100;
    transformStart = percent;
}

/**
 * What to do with a mousedown / touchStart etc.
 *
 * @return {null} Returns nothing.
 */
function mouseDownEvent(e) {
    inDrag = true;
    objInitLeft = dragTarget.offsetLeft;
    dragStartX = e.pageX;
    dragTarget.style.cursor = 'grabbing';

    getTransformPosition();
}

/**
 * What to do with a mousemove / touchMove etc.
 *
 * @return {null} Returns nothing. Sets the transform value on the container
 */
function mouseMoveEvent(e) {
    if (!inDrag) {return;}
    // console.log('is moving');

    var offset = objInitLeft;
    var dragDistance = e.pageX - dragStartX;
    var moveValue = offset + dragDistance;

    var style = document.documentElement.style;
    var transformProperty = toWebkitOrNot();

    var elWidth = dragTarget.offsetWidth;
    var percent = (moveValue / elWidth) * 100;

    moveValue = percent + parseFloat(transformStart);

    dragTarget.style[transformProperty] = "translate3d(" + moveValue + "%, 0, 0)";
}

/**
 * What to do with a mouseEnd / touchEnd etc.
 *
 * @return {null} Returns nothing. Chooses the finishing state and resets
 * variable styles
 */
function mouseEndEvent(e) {
    // Work out which side to end up on after drag
    var direction;
    if ((e.pageX - dragStartX >= 100 ) ){
        direction = 'right';
    } else  if ( dragStartX - e.pageX >= 100 ) {
        direction = 'left';
    } else {
        direction = dragTarget.getAttribute('data-direction');
    }

    // Pause all pane videos
    var panes = document.querySelectorAll('.pane');
    if (panes) {
        Array.prototype.forEach.call(panes, function(el, i) {
            el.querySelector('.video').pause();
        });
    }

    // Get the active pane based on above drag
    var activePane = document.querySelector('.pane[data-direction="' + direction + '"]');

    // Play that video
    // TODO: only play video if it supports inline video
    if ('playsInline' in document.createElement('video')) {
        activePane.querySelector('.video').play();
    }

    // Set the direction on the pane wrapper
    dragTarget.setAttribute('data-direction', direction);

    // Clear all styles
    dragTarget.removeAttribute("style")

    // turn off dragging flag
    inDrag = false;
}

/**
 * Setting up all event listeners on the container
 *
 * @return {null} Returns nothing.
 */
function addEventListeners() {
    dragTarget.addEventListener("mousedown", mouseDownEvent);
    dragTarget.addEventListener("pointerdown", mouseDownEvent);
    dragTarget.addEventListener("touchstart", mouseDownEvent);

    dragTarget.addEventListener("mousemove", mouseMoveEvent);
    dragTarget.addEventListener("pointermove", mouseMoveEvent);
    dragTarget.addEventListener("touchmove", mouseMoveEvent);

    dragTarget.addEventListener("mouseup", mouseEndEvent);
    dragTarget.addEventListener("pointerup", mouseMoveEvent);
    dragTarget.addEventListener("touchend", mouseEndEvent);
}

// If there is a container on this page
if (dragTarget) {
    // Then add all the event listeners
    addEventListeners();
}

// Work out the windows width
var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

// If we are dealing with a samller screen
if (windowWidth <= 800 && dragTarget)  {

    // Get the current state
    var direction = paneWrapper.getAttribute('data-direction');

    // Set the video accordingly
    if (direction == 'left') {
        paneWrapper.querySelector('.pane--league').querySelector('video').play();
    } else {
        paneWrapper.querySelector('.pane--stranger').querySelector('video').play();
    } 
}
